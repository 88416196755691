/* eslint-disable arrow-body-style */
import { postMethod } from "../apiConfig";

export const getRoleModulesByRolesApi = (data) => {
    return postMethod('/superadmin/fetchAllModulesByRole', data);
};

export const getStudentApi = (data) => {
    return postMethod('/superadmin/get-student', data);
};

export const deleteStudentApi = (data) => {
    return postMethod('/superadmin/delete-student', data);
};

export const addUpdateRolePermissionApi = (data) => {
    return postMethod('/superadmin/addUpdatePermissions', data);
};
/* eslint-disable arrow-body-style */
